import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import useStaticModule from '@hooks/useStaticModule'
import { useIntl } from 'react-intl'
import { makeStyles } from '@material-ui/core/styles'

import Container from '@objects/container'
import NewsletterTeaser from '@components/newsletterTeaser'
import TripleIcon from '@components/tripleIcon'
import ImageCard from '@components/imageCard'
import Stage from '@components/stage'
import MediaTeaser from '@components/mediaTeaser'

const useStyles = makeStyles((theme) => ({
  gallery: {
    marginTop: theme.spacing(15),
    '& ~ &': {
      marginTop: theme.spacing(30),
    },
  },
}))

function MediaPage({ data, pageContext }) {
  const classes = useStyles()
  const {
    getStaticFieldValue,
    getStaticFieldLink,
    getStaticFieldMedia,
    getStaticFieldButton,
  } = useStaticModule(pageContext.modules)

  const intl = useIntl()

  const stage = {
    headline: getStaticFieldValue('media.stage', `media.stage.headline`),
    copy: getStaticFieldValue('media.stage', `media.stage.copy`),
  }

  const genericImageCard = {
    subline: getStaticFieldValue('media.teaser', 'media.teaser.subline'),
    headline: getStaticFieldValue('media.teaser', 'media.teaser.headline'),
    copy: getStaticFieldValue('media.teaser', 'media.teaser.copy'),
    image: getStaticFieldMedia('media.teaser', 'media.teaser.image'),
    button: getStaticFieldButton('media.teaser', 'media.teaser.button'),
  }

  const iconGroup = {
    headline: getStaticFieldValue(
      'media.tripleicon',
      `media.tripleicon.headline`
    ),
    items: ['1', '2'].map((count) => {
      return {
        headline: getStaticFieldValue(
          'media.tripleicon',
          `media.tripleicon.${count}.headline`
        ),
        copy: getStaticFieldValue(
          'media.tripleicon',
          `media.tripleicon.${count}.copy`
        ),
        link: getStaticFieldLink(
          'media.tripleicon',
          `media.tripleicon.${count}.link`
        ),
        icon: getStaticFieldValue(
          'media.tripleicon',
          `media.tripleicon.${count}.icon`
        ),
      }
    }),
  }

  return (
    <>
      {!!stage.headline && (
        <Stage
          alignTop={true}
          title={stage.headline}
          ariaLabel={stage.headline}
          copy={stage.copy}
          type="mediapage"
        />
      )}

      {!!iconGroup && (
        <Container type="top" ariaLabel="arialabel.medien">
          <TripleIcon headline={iconGroup.headline} items={iconGroup.items} />
        </Container>
      )}

      {!!data.contentfulMediaList && (
        <MediaTeaser
          className={classes.gallery}
          title="Aktuelle Medien zu 5G"
          mediaTeaserList={data.contentfulMediaList?.files}
          button={intl.formatMessage({ id: 'mediathek.path' })}
          button2={intl.formatMessage({ id: 'printmaterial.path' })}
          isMediaPage
        />
      )}

      {genericImageCard.headline && (
        <ImageCard
          // subline={intl.formatMessage({
          //   id: 'article.press.subline', // article.press.subline is DEPRECATED
          // })}
          headline={genericImageCard.headline}
          barcolor="yellow"
          button={{
            label: intl.formatMessage({ id: 'read.more' }),
            link: genericImageCard.button.link,
            color: 'yellow',
            icon: 'TextArrow',
            type: 'secondary',
          }}
          image={genericImageCard.image}
          containImage
        />
      )}

      <NewsletterTeaser
        title={intl.formatMessage({ id: 'newsletter.hl' })}
        copy={intl.formatMessage({ id: 'newsletter.copy' })}
      />
    </>
  )
}

MediaPage.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default MediaPage

export const pageQuery = graphql`
  query MediaPageQuery($locale: String!) {
    contentfulMediaList(
      title: { eq: "Aktuelle Medien zu 5G" }
      node_locale: { eq: $locale }
    ) {
      id
      title
      files {
        __typename
        ... on ContentfulImage {
          id
          title
          trackingTitle
          createdAt
          updatedAt
          image {
            description
            file {
              details {
                image {
                  height
                  width
                }
                size
              }
              contentType
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          intro {
            intro
          }
        }
        ... on ContentfulVideo {
          id
          title
          createdAt
          updatedAt
          thumbnail {
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          media {
            description
            file {
              url
            }
          }
          boxCopy {
            boxCopy
          }
        }
        ... on ContentfulYoutube {
          id
          title
          youTubeKey
          createdAt
          updatedAt
          thumbnail {
            description
            file {
              details {
                image {
                  height
                  width
                }
              }
              url
            }
            fluid(quality: 80, maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          intro {
            intro
          }
        }
        ... on ContentfulPrintMaterial {
          id
          title
          trackingTitle
          createdAt
          updatedAt
          subtitle
          copy {
            copy
          }
          images {
            id
            title
            description
            fluid(maxWidth: 2000, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          variants {
            id
            variantName
            variantFile {
              file {
                details {
                  size
                }
                contentType
                url
              }
            }
          }
          isOnlyDownload
        }
      }
    }
  }
`
