import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Icon from '@objects/icon'

const useStyles = makeStyles((theme) => ({
  headline: {
    ...theme.typography.h3,
    marginBottom: theme.spacing(12),
  },
  teaser: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  heading: {
    margin: theme.spacing(0, 0, 3, 0),
    color: theme.palette.red.main,
    fontWeight: 700,
  },
  copy: {
    ...theme.typography.teaser,
  },
  icon: {
    backgroundColor: theme.palette.yellow.main,
    borderRadius: '50%',
    lineHeight: 1,
    fontSize: theme.spacing(8),
    padding: theme.spacing(4),
    marginRight: theme.spacing(8),
    ':focus > &': {
      backgroundColor: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    ':hover > &': {
      backgroundColor: theme.palette.orange.main,
      color: 'inherit',
    },
  },
  iconLarge: {
    height: '64px',
    width: '64px',
    fontSize: '49px',
    padding: '8px',
    backgroundColor: theme.palette.yellow.main,
    borderRadius: '50%',
    lineHeight: 1,
    marginRight: theme.spacing(8),
    ':focus > &': {
      backgroundColor: theme.palette.background.focus,
      color: theme.palette.text.invert,
    },
    ':hover > &': {
      backgroundColor: theme.palette.orange.main,
      color: 'inherit',
    },
  },
}))

function TripleIcon({ headline, items }) {
  const classes = useStyles()

  const icons = ['Question', 'Contribute', 'Image']

  function Wrapper({ children, link }) {
    if (link) {
      if (link.indexOf('mailto') > -1) {
        return (
          <a
            data-track-content
            data-tracking-id={link}
            className={classes.teaser}
            href={link}
          >
            {children}
          </a>
        )
      } else {
        return (
          <Link
            data-track-content
            data-tracking-id={link}
            className={classes.teaser}
            to={link}
          >
            {children}
          </Link>
        )
      }
    } else {
      return <div className={classes.teaser}>{children}</div>
    }
  }

  function renderTexts() {
    return items.map((item, i) => (
      <Grid key={`item${i}`} item xs={12} md={6}>
        <Wrapper link={item.link}>
          <div
            className={
              item.icon === 'AssignmentIndOutlined'
                ? classes.iconLarge
                : classes.icon
            }
          >
            <Icon
              name={!!item.icon ? item.icon : icons[i] || icons[0]}
              size="inherit"
            />
          </div>
          <div>
            <p className={classes.heading}>{item.headline}</p>
            <Copy className={classes.copy} html={item.copy} />
          </div>
        </Wrapper>
      </Grid>
    ))
  }

  return (
    <>
      <Headline className={classes.headline} level={2}>
        {headline}
      </Headline>
      <Grid container spacing={8}>
        {renderTexts()}
      </Grid>
    </>
  )
}

TripleIcon.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      copy: PropTypes.string,
      link: PropTypes.string,
    })
  ),
}

export default TripleIcon
